import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { patterns } from "src/app/shared/helpers/patterns.helper";
import { LoginService } from "../services/login.service";


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  groupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService

  ) {
    this.groupForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.groupForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])],
      name: ['', Validators.required],
      mensagem: ['', Validators.required]
    });
  }

  submit() {
    if(this.groupForm.valid){
      this.spinner.show()

      this.loginService.contact(this.groupForm.value).subscribe(response =>{
        this.spinner.hide()
        this.router.navigate(['../']);
        this.toastr.success('Sucesso...')

      }, error=>{
        this.spinner.hide()
        this.toastr.error(`${error.error.error}`)
        this.groupForm.reset()
      })
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }

  }

}
