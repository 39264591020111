import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TextsService } from '../services/texts.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  terms

  constructor(
    private textsService: TextsService,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.start()
  }

  async start(){
    try{
      await this.getTerms()
    }catch{
      this.toastr.error('Não foi possível carregar os Termos de Uso', 'Error !')
    }
  }

  getTerms(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.textsService.getTermsOfUse().subscribe(response =>{
        this.terms = response.readPolices
        console.log(this.terms)
        resolve(response)
      }, error=>{
        reject(error)
      })
    })
  }
}
