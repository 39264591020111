<div class="bg">
  <form [formGroup]="groupForm">
    <img src="../../assets/wipstorelogo.png" alt="" class="logo">
    <div class="notificacao">
        <div class="inputs">
            
            <h4 class="pr-3">
              <h4 class="sign">Fale conosco!</h4></h4>
            <div class="pt-2">
              <input type="text" id="name" formControlName="name" placeholder="Nome" name="uname" required>
            </div>
            <div class="pt-2">
              <input type="text" id="email" formControlName="email" placeholder="E-mail" name="uname" required>
            </div>
            <div class="pt-2">
              <textarea type="text" id="mensagem" formControlName="mensagem" placeholder="Mensagem" name="uname" required> </textarea>
            </div>
        </div>
        <div class="buttons align col-7 ml-5">
            <button (click)="submit()">Enviar</button>
        </div>
    </div>
  </form>
</div>