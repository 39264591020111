<div (window:resize)="checkWindowSize()" [class.smartphone]="isSmartphone" class="wrapper">
  <nav class="sidebar" [class.hide-sidebar]="hideSidebar">
    <div class="row m-0 mt-5">
      <div class="col-12 nav-header">      
        <img src="../../assets/wipstorelogo.png" alt="" class="logo">
      </div>
      <div class="row w-100 m-0 mt-5 only-nav">
        <ul>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./store">
              <div class="section-name">
               <i class="fas fa-store"></i><span>Lojas</span>
        
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./usuarios">
              <div class="section-name">
                <i class="far fa-user"></i><span>Usuários</span>
              </div>
            </a>
          </li>
          <li (click)="checkCollapse()" [routerLinkActive]="['link-focus']">
            <a routerLink="./relatorio">
              <div class="section-name">
                <i class="fas fa-chart-line"></i><span>Relatório</span>
              </div>
            </a>
          </li>       
          <li (click)="logout()">
            <a>
              <div class="section-name">
                  <i class="fas fa-times"></i>
                <span>Sair</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div [class.hide-sidebar]="hideSidebar" class="content">
    <div class="container-fluid min-vh-100 position-relative">
      <button (click)="hideSidebar = !hideSidebar" [class.d-none]="!isSmartphone" class="btn toggle">
     
        <i class='fas fa-align-justify' style='font-size:24px;color: aliceblue;' alt="toggle"></i>
      </button>
      <ng-content></ng-content>
    </div>
  </div>
</div>