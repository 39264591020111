import { Router } from '@angular/router';
import { LoginService } from './../services/login.service';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {patterns} from '../../shared/helpers/patterns.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide = true;
  loginForm: FormGroup;
  value
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.loginForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])],
      password: ['', [Validators.required]],
      role_id: 4
    });
  }

  submit(){
    if(this.loginForm.valid){
      this.spinner.show()
      this.loginService.login(this.loginForm.value).subscribe(response => {
        this.spinner.hide();
        this.router.navigate(['/admin']);

      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.msg);
        this.loginForm.reset();

      })

    }else{
      this.toastr.error('Preencha os campos inválidos');
      
    }
  }

  get email(){
    return this.loginForm.get('email')
  }

  get password(){
    return this.loginForm.get('password')
  }
}
