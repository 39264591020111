import { API } from './../../app.api';
import { UserLoggedin } from './../../shared/models/user.model';
import { StorageService } from './localsorage.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  helper = new JwtHelperService();
  private _user: UserLoggedin;

  constructor(
    private http: HttpClient,
    private router: Router,
    private localstorageService: StorageService
  ) {}

  isLoggedIn(): boolean {
    if(this.localstorageService.getLocalStorage("user_wip_store_web")){
      return true
    }
  }

  login(body): Observable<any>{
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response'
  };
    return this.http.post(`${API}/login`, body, httpOptions)
      .pipe(tap(user =>{
        const token: string = user.headers.get('authorization');
        user.token = token;
        this._user = user
        this.initUser(user)
      }))
  }

  logout() {
    this.localstorageService.removeLocalStorage("user_wip_store_web")
    delete this._user;
    this.router.navigate(['/'])
  }

  private initUser(response) {
    this._user = response;
    this.localstorageService.setLocalStorage("user_wip_store_web", this._user)
  }

  getRealStates(): Observable<any>{
    return this.http.get(`${API}/realestate/all`)
  }

  recupera(body): Observable<any>{
    return this.http.post(`${API}/recoveryPassword`, body)
  }
  
  contact(body): Observable<any>{
    return this.http.post(`${API}/contact`, body)
  }


  postSupport(body): Observable<any>{
    return this.http.post(`${API}/contact/support`, body)
  }

  private isValid() {
    return !!this.user;
  }

  get user() {
    if (this._user === undefined && this.localstorageService.getLocalStorage("user_wip_store_web") != null) {
      this._user = this.localstorageService.getLocalStorage("user_wip_store_web");
    }
    return this._user;
  }

  getTokenExpired(){
    console.log(this.helper.isTokenExpired(this.user.refreshToken))
      return this.helper.isTokenExpired(this.user.refreshToken)
  }

  get token() {
    return this.isValid() && this.user.token;
  }

  get getDay(){
    var dataAtual = new Date();
    var dia = dataAtual.getDate();
    var mes = dataAtual.getMonth()
    var ano = dataAtual.getFullYear();

    return `${dia}/${mes}/${ano}`
  }

}
