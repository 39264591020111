<div class="container-fluid d-flex justify-content-center align-items-center">
    <form [formGroup]="loginForm" (ngSubmit)="submit()" class="login form-row">
      
      <div class="card" style="border: 1px solid #313131  !important">
        <div class="card-body" style="border: 1px solid #313131  !important">
          <div class="col-12 d-flex justify-content-center logo">
            <img src="../../assets/wipstorelogo.png" alt="" class="logo">
          </div>
          <div class="d-flex justify-content-center logo">
 
          </div>
          <div class="col-12  form-group">
            <input type="text" class="col-12 ml-1" formControlName="email" id="email" placeholder="Email" name="uname" required>
        </div>
          <div class="col-12 form-group" style="display:flex">
            <input class="col-11  pl-0" [type]="hide ? 'password' : 'text'"  placeholder="Senha" formControlName="password" id="password" name="psw" required>
            <div class="input-group-append">
            <span class="input-group-text" (click)="hide = !hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            </div>         
         </div>
          <div class="col-12 esquece">
            <a routerLink="/recuperar-senha" style="color: white !important;">Esqueci minha senha</a>
          </div>
          <div class="col-12 form-group button">
          <button type="submit" (click)="submit()" class="btn btn-warning btn-sm btn-block p-2"><strong>Entrar</strong></button>
          </div>
          <div class="col-12 mt-4  footer">
            <a style="color:aliceblue  !important;" routerLink="/termos-de-uso">Termos de uso</a><span class="pl-2 pr-2" style="color: aliceblue  !important;"> ● </span><a routerLink="/politicas-de-privacidade" style="color:aliceblue  !important;">Política de Privacidade</a><span class="pl-2 pr-2" style="color: aliceblue;"> ● </span><a routerLink="/contact" style="color:aliceblue  !important;">Contato</a>
          </div>
        </div>
      </div>
    </form>
  </div>
  