<div class="bg">
  <form [formGroup]="forgotPasswordForm">
    <img src="../../assets/wipstorelogo.png" alt="" class="logo">
    <div class="notificacao">
        <div class="inputs">
            
            <h4 class="pl-5 pr-3">
              <h4 class="sign">Esqueci minha senha</h4>
              Enviaremos um código de recuperação para o seu email <br> ex***@mail.com</h4>
            <input type="text" id="email" formControlName="email" placeholder="Email" name="uname"  class="col-7 align" required>
        </div>
        <div class="buttons align col-9">
            <button (click)="submit()">Enviar</button>
        </div>
    </div>
  </form>
</div>