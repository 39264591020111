import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {patterns} from '../../shared/helpers/patterns.helper';
import { LoginComponent } from '../login/login.component';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-password-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss']
})
export class PasswordRecoverComponent implements OnInit {

  forgotPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService

  ) {
    this.forgotPasswordForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(patterns.email)])]
    });
  }

  submit() {
    if(this.forgotPasswordForm.valid){
      this.spinner.show()

      this.loginService.recupera(this.forgotPasswordForm.value).subscribe(response =>{
        this.spinner.hide()
        this.router.navigate(['../']);
        this.toastr.success('Sucesso...')

      }, error=>{
        this.spinner.hide()
        this.toastr.error(`${error.error.msg}`)
        this.forgotPasswordForm.reset()
      })
    }else{
      this.toastr.error('Preencha os campos inválidos')
    }

  }

}
