<div class="bg">
  <img src="../../assets/wipstorelogo.png" alt="" class="logo">
  <div class="container" data-spy="scroll" data-target=".navbar" data-offset="50">
      <p class="sign">{{privacy?.title}}</p>
         <div class="card-terms">
          <div class="card-body">
              <div [innerHTML]="privacy?.description"></div>
          </div>
          <div class="col text-center btn-voltar">
            <a href="../"><button class="btn btn-primary">Voltar</button></a>
        </div>
      </div>
  </div>
</div>